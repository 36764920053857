import React from 'react'

export default function MobileCoinLogo() {
  return (
    <svg
      width="45"
      height="40"
      viewBox="0 0 45 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.8032 0C10.1921 0 0.800049 8.95449 0.800049 20.0246C0.800049 31.0455 10.2437 40 21.8032 40C33.4143 40 42.8064 30.9963 42.8064 20.0246C42.8064 8.95449 33.3627 0 21.8032 0ZM17.8622 23.5665C17.7133 23.5668 17.576 23.4938 17.4867 23.3804C16.9122 22.651 16.0398 22.1894 14.9914 22.1894C13.2884 22.1894 11.8435 23.5178 11.8435 25.1907C11.8435 26.8143 13.2368 28.1919 14.9914 28.1919C16.0349 28.1919 16.9913 27.6931 17.5398 26.9247C17.6292 26.7995 17.7735 26.7159 17.9325 26.7159H36.2237C36.5337 26.7159 36.7448 27.0145 36.6173 27.2836C34.216 32.3509 28.9695 35.9656 21.8032 35.9656C11.5855 35.9656 5.13486 28.8315 5.13486 20.0738C5.13486 19.354 5.21129 18.7072 5.33126 18.0077C5.36549 17.8082 5.54771 17.663 5.76007 17.663H26.2537C26.4062 17.663 26.546 17.7399 26.635 17.8579C27.2161 18.6273 28.1333 19.0898 29.1827 19.0898C30.8857 19.0898 32.3306 17.7614 32.3306 16.0886C32.3306 14.4649 30.9373 13.0873 29.1827 13.0873C28.1395 13.0873 27.1834 13.5443 26.6348 14.3056C26.5448 14.4304 26.4007 14.5141 26.2417 14.5141H6.72538C6.42587 14.5141 6.2151 14.234 6.32117 13.9673C8.58044 8.28589 14.1013 4.03444 21.8032 4.03444C31.8662 4.03444 38.5232 11.1685 38.5232 19.9754C38.5232 21.093 38.3528 22.17 38.0894 23.2064C38.0426 23.3903 37.8686 23.5182 37.6699 23.5187L17.8622 23.5665Z"
        fill="white"
      />
    </svg>
  )
}
