import React from 'react'

export const ReserveIcon = ({ pxSize }: { pxSize?: number }) => {
  return (
    <svg
      width={pxSize ?? 20}
      height={pxSize ?? 20}
      viewBox="0 0 3500 3500"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g>
          <g>
            <path
              id="SVGID_1_"
              d="M1750,0c966.5,0,1750,783.5,1750,1750s-783.5,1750-1750,1750S0,2716.5,0,1750S783.5,0,1750,0L1750,0z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              id="SVGID_3_"
              fill="#FFFFFF"
              d="M909.5,1428.6c0,13.1,13.1,26.2,26.2,26.2H1254v161.8H935.7c-13.1,0-26.2,13.1-26.2,26.2
				v266.8c0,13.1,13.1,26.2,26.2,26.2H1254v638.6c0,13.1,8.7,21.9,21.8,21.9h305.3c13.1,0,21.8-8.7,21.8-21.9V1971
				c0-13.1,8.7-21.9,21.8-21.9H1869c8.7,0,17.4,4.4,21.8,13.1l270.4,625.5c4.4,8.7,13.1,13.1,21.8,13.1h327.1
				c17.4,0,26.2-17.5,21.8-30.6l-270.4-612.4c-4.4-13.1,0-26.2,13.1-30.6c130.8-48.1,213.7-166.2,213.7-323.7v-393.7
				c0-201.2-165.7-367.4-366.4-367.4h-846.1c-13.1,0-21.8,8.8-21.8,21.9v271.2H935.7c-13.1,0-26.2,13.1-26.2,26.2V1428.6
				L909.5,1428.6z"
            />
          </g>
          <g>
            <path
              id="SVGID_4_"
              d="M1602.9,1196.7c0-13.1,8.7-21.9,21.8-21.9h410c56.7,0,104.7,48.1,104.7,105V1516c0,56.9-48,105-104.7,105
				h-410c-13.1,0-21.8-8.8-21.8-21.9L1602.9,1196.7L1602.9,1196.7z"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
