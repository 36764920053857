import React from 'react'

export const MCIcon = (color?: string, pxSize?: number) => {
  if (!color) {
    color = '#027cfd'
  }
  if (!pxSize) {
    pxSize = 48
  }
  return (
    <svg
      width={pxSize}
      height={pxSize}
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.0209 0C7.61131 0 0 7.61131 0 17.0209C0 26.3887 7.65313 34 17.0209 34C26.4305 34 34.0418 26.3469 34.0418 17.0209C34.0418 7.61131 26.3887 0 17.0209 0ZM13.6335 20.032C13.1734 19.321 12.4207 18.861 11.5006 18.861C10.1205 18.861 8.94957 19.9902 8.94957 21.4121C8.94957 22.7921 10.0787 23.9631 11.5006 23.9631C12.4207 23.9631 13.2571 23.4613 13.6753 22.7085H29.2325C27.3924 27.2669 23.043 30.5707 17.0209 30.5707C8.74047 30.5707 3.51292 24.5068 3.51292 17.0627C3.51292 16.3518 3.59656 15.7245 3.72202 15.0135H20.8266C21.2866 15.7663 22.0812 16.2263 23.0012 16.2263C24.3813 16.2263 25.5523 15.0972 25.5523 13.6753C25.5523 12.2952 24.4231 11.1242 23.0012 11.1242C22.0812 11.1242 21.2448 11.5843 20.8266 12.337H4.3075C6.02214 7.27675 10.5806 3.42927 17.0209 3.42927C25.1759 3.42927 30.5707 9.49323 30.5707 16.9791C30.5707 18.0246 30.4034 19.0283 30.1525 19.9902H13.6335V20.032Z"
        fill={color}
      />
    </svg>
  )
}
