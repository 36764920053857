import React from 'react'

export const WrapMintIcon = ({ pxSize }: { pxSize?: number }) => {
  if (!pxSize) {
    pxSize = 24
  }
  return (
    <svg
      width={pxSize}
      height={pxSize}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 4V12C14 13.105 11.3125 14 8 14C4.6875 14 2 13.105 2 12V4C2 2.895 4.6875 2 8 2C11.3125 2 14 2.895 14 4Z"
        fill="#01FFC0"
      />
      <path
        d="M14 4V12C14 13.105 11.3125 14 8 14C4.6875 14 2 13.105 2 12V4C2 2.895 4.6875 2 8 2C11.3125 2 14 2.895 14 4Z"
        fill="black"
        fillOpacity="0.6"
      />
      <path
        d="M14 4V8.5C14 9.605 11.3125 10.5 8 10.5C4.6875 10.5 2 9.605 2 8.5V4C2 2.895 4.6875 2 8 2C11.3125 2 14 2.895 14 4Z"
        fill="#00FFBF"
      />
      <path
        d="M14 4V8.5C14 9.605 11.3125 10.5 8 10.5C4.6875 10.5 2 9.605 2 8.5V4C2 2.895 4.6875 2 8 2C11.3125 2 14 2.895 14 4Z"
        fill="black"
        fillOpacity="0.3"
      />
      <rect x="2" y="3" width="12" height="3" rx="1.5" fill="#00FFBF" />
      <mask id="path-4-inside-1_9_7682" fill="white">
        <path d="M14 4C14 5.105 11.3125 6 8 6C4.6875 6 2 5.105 2 4" />
      </mask>
      <path
        d="M13.75 4C13.75 4.1668 13.6488 4.36546 13.3736 4.5823C13.1012 4.79693 12.6916 5.00111 12.1632 5.1772C11.1089 5.5285 9.63686 5.75 8 5.75V6.25C9.67564 6.25 11.2036 6.024 12.3212 5.65155C12.8787 5.46577 13.348 5.23901 13.683 4.97504C14.0153 4.71329 14.25 4.3857 14.25 4H13.75ZM8 5.75C6.36314 5.75 4.89106 5.5285 3.83685 5.1772C3.30843 5.00111 2.8988 4.79693 2.6264 4.5823C2.35119 4.36546 2.25 4.1668 2.25 4H1.75C1.75 4.3857 1.98475 4.71329 2.31696 4.97504C2.65198 5.23901 3.12125 5.46577 3.67878 5.65155C4.79644 6.024 6.32436 6.25 8 6.25V5.75Z"
        fill="#00FFBF"
        mask="url(#path-4-inside-1_9_7682)"
      />
      <mask id="path-6-inside-2_9_7682" fill="white">
        <path d="M14 6.19995C14 7.30495 11.3125 8.19995 8 8.19995C4.6875 8.19995 2 7.30495 2 6.19995" />
      </mask>
      <path
        d="M14 6.19995C14 7.30495 11.3125 8.19995 8 8.19995C4.6875 8.19995 2 7.30495 2 6.19995"
        fill="#00FFBF"
      />
      <path
        d="M13.2 6.19995C13.2 6.12597 13.2457 6.18279 13.0332 6.35024C12.8297 6.51058 12.4857 6.68994 11.9893 6.85536C11.0048 7.18341 9.59421 7.39995 8 7.39995V8.99995C9.71829 8.99995 11.3077 8.769 12.4951 8.37329C13.0846 8.17684 13.6195 7.92526 14.0234 7.60701C14.4183 7.29586 14.8 6.82644 14.8 6.19995H13.2ZM8 7.39995C6.40579 7.39995 4.99516 7.18341 4.01073 6.85536C3.51433 6.68994 3.17029 6.51058 2.96679 6.35024C2.75427 6.18279 2.8 6.12597 2.8 6.19995H1.2C1.2 6.82644 1.58167 7.29586 1.97657 7.60701C2.38049 7.92526 2.91536 8.17684 3.5049 8.37329C4.69234 8.769 6.28171 8.99995 8 8.99995V7.39995Z"
        fill="#00FFBF"
        mask="url(#path-6-inside-2_9_7682)"
      />
      <rect x="2" y="5.19995" width="12" height="1" fill="#00FFBF" />
      <rect x="2" y="5.19995" width="12" height="0.4" fill="#00FFBF" />
      <mask id="path-10-inside-3_9_7682" fill="white">
        <path d="M14 5.59998C14 6.70498 11.3125 7.59998 8 7.59998C4.6875 7.59998 2 6.70498 2 5.59998" />
      </mask>
      <path
        d="M13.2 5.59998C13.2 5.52599 13.2457 5.58282 13.0332 5.75026C12.8297 5.91061 12.4857 6.08996 11.9893 6.25538C11.0048 6.58343 9.59421 6.79998 8 6.79998V8.39998C9.71829 8.39998 11.3077 8.16902 12.4951 7.77332C13.0846 7.57686 13.6195 7.32528 14.0234 7.00703C14.4183 6.69588 14.8 6.22646 14.8 5.59998H13.2ZM8 6.79998C6.40579 6.79998 4.99516 6.58343 4.01073 6.25538C3.51433 6.08996 3.17029 5.91061 2.96679 5.75026C2.75427 5.58282 2.8 5.52599 2.8 5.59998H1.2C1.2 6.22646 1.58167 6.69588 1.97657 7.00703C2.38049 7.32528 2.91536 7.57686 3.5049 7.77332C4.69234 8.16902 6.28171 8.39998 8 8.39998V6.79998Z"
        fill="#00FFBF"
        mask="url(#path-10-inside-3_9_7682)"
      />
      <mask id="path-12-inside-4_9_7682" fill="white">
        <path d="M14 5.19995C14 6.30495 11.3125 7.19995 8 7.19995C4.6875 7.19995 2 6.30495 2 5.19995" />
      </mask>
      <path
        d="M13.2 5.19995C13.2 5.12597 13.2457 5.18279 13.0332 5.35024C12.8297 5.51058 12.4857 5.68994 11.9893 5.85536C11.0048 6.18341 9.59421 6.39995 8 6.39995V7.99995C9.71829 7.99995 11.3077 7.769 12.4951 7.37329C13.0846 7.17684 13.6195 6.92526 14.0234 6.60701C14.4183 6.29586 14.8 5.82644 14.8 5.19995H13.2ZM8 6.39995C6.40579 6.39995 4.99516 6.18341 4.01073 5.85536C3.51433 5.68994 3.17029 5.51058 2.96679 5.35024C2.75427 5.18279 2.8 5.12597 2.8 5.19995H1.2C1.2 5.82644 1.58167 6.29586 1.97657 6.60701C2.38049 6.92526 2.91536 7.17684 3.5049 7.37329C4.69234 7.769 6.28171 7.99995 8 7.99995V6.39995Z"
        fill="url(#paint0_linear_9_7682)"
        mask="url(#path-12-inside-4_9_7682)"
      />
      <mask id="path-14-inside-5_9_7682" fill="white">
        <path d="M14 4.80005C14 5.90505 11.3125 6.80005 8 6.80005C4.6875 6.80005 2 5.90505 2 4.80005" />
      </mask>
      <path
        d="M13.2 4.80005C13.2 4.72606 13.2457 4.78289 13.0332 4.95034C12.8297 5.11068 12.4857 5.29004 11.9893 5.45546C11.0048 5.7835 9.59421 6.00005 8 6.00005V7.60005C9.71829 7.60005 11.3077 7.36909 12.4951 6.97339C13.0846 6.77694 13.6195 6.52536 14.0234 6.2071C14.4183 5.89596 14.8 5.42653 14.8 4.80005H13.2ZM8 6.00005C6.40579 6.00005 4.99516 5.7835 4.01073 5.45546C3.51433 5.29004 3.17029 5.11068 2.96679 4.95034C2.75427 4.78289 2.8 4.72606 2.8 4.80005H1.2C1.2 5.42653 1.58167 5.89596 1.97657 6.2071C2.38049 6.52536 2.91536 6.77694 3.5049 6.97339C4.69234 7.36909 6.28171 7.60005 8 7.60005V6.00005Z"
        fill="#00FFBF"
        mask="url(#path-14-inside-5_9_7682)"
      />
      <mask id="path-16-inside-6_9_7682" fill="white">
        <path d="M14 4.40002C14 5.50502 11.3125 6.40002 8 6.40002C4.6875 6.40002 2 5.50502 2 4.40002" />
      </mask>
      <path
        d="M13.2 4.40002C13.2 4.32604 13.2457 4.38287 13.0332 4.55031C12.8297 4.71066 12.4857 4.89001 11.9893 5.05543C11.0048 5.38348 9.59421 5.60002 8 5.60002V7.20002C9.71829 7.20002 11.3077 6.96907 12.4951 6.57337C13.0846 6.37691 13.6195 6.12533 14.0234 5.80708C14.4183 5.49593 14.8 5.02651 14.8 4.40002H13.2ZM8 5.60002C6.40579 5.60002 4.99516 5.38348 4.01073 5.05543C3.51433 4.89001 3.17029 4.71066 2.96679 4.55031C2.75427 4.38287 2.8 4.32604 2.8 4.40002H1.2C1.2 5.02651 1.58167 5.49593 1.97657 5.80708C2.38049 6.12533 2.91536 6.37691 3.5049 6.57337C4.69234 6.96907 6.28171 7.20002 8 7.20002V5.60002Z"
        fill="url(#paint1_linear_9_7682)"
        mask="url(#path-16-inside-6_9_7682)"
      />
      <mask id="path-18-inside-7_9_7682" fill="white">
        <path d="M14 4C14 5.105 11.3125 6 8 6C4.6875 6 2 5.105 2 4" />
      </mask>
      <path
        d="M13.2 4C13.2 3.92601 13.2457 3.98284 13.0332 4.15029C12.8297 4.31063 12.4857 4.48999 11.9893 4.65541C11.0048 4.98345 9.59421 5.2 8 5.2V6.8C9.71829 6.8 11.3077 6.56905 12.4951 6.17334C13.0846 5.97689 13.6195 5.72531 14.0234 5.40706C14.4183 5.09591 14.8 4.62649 14.8 4H13.2ZM8 5.2C6.40579 5.2 4.99516 4.98345 4.01073 4.65541C3.51433 4.48999 3.17029 4.31063 2.96679 4.15029C2.75427 3.98284 2.8 3.92601 2.8 4H1.2C1.2 4.62649 1.58167 5.09591 1.97657 5.40706C2.38049 5.72531 2.91536 5.97689 3.5049 6.17334C4.69234 6.56905 6.28171 6.8 8 6.8V5.2Z"
        fill="#00FFBF"
        mask="url(#path-18-inside-7_9_7682)"
      />
      <mask id="path-20-inside-8_9_7682" fill="white">
        <path d="M14 4C14 2.895 11.3125 2 8 2C4.6875 2 2 2.895 2 4" />
      </mask>
      <path
        d="M13.2 4C13.2 4.07399 13.2457 4.01716 13.0332 3.84971C12.8297 3.68937 12.4857 3.51001 11.9893 3.34459C11.0048 3.01655 9.59421 2.8 8 2.8V1.2C9.71829 1.2 11.3077 1.43095 12.4951 1.82666C13.0846 2.02311 13.6195 2.27469 14.0234 2.59294C14.4183 2.90409 14.8 3.37351 14.8 4H13.2ZM8 2.8C6.40579 2.8 4.99516 3.01655 4.01073 3.34459C3.51433 3.51001 3.17029 3.68937 2.96679 3.84971C2.75427 4.01716 2.8 4.07399 2.8 4H1.2C1.2 3.37351 1.58167 2.90409 1.97657 2.59294C2.38049 2.27469 2.91536 2.02311 3.5049 1.82666C4.69234 1.43095 6.28171 1.2 8 1.2V2.8Z"
        fill="#00FFBF"
        mask="url(#path-20-inside-8_9_7682)"
      />
      <mask id="path-22-inside-9_9_7682" fill="white">
        <path d="M14 4.80005C14 3.69505 11.3125 2.80005 8 2.80005C4.6875 2.80005 2 3.69505 2 4.80005" />
      </mask>
      <path
        d="M13.2 4.80005C13.2 4.87403 13.2457 4.81721 13.0332 4.64976C12.8297 4.48942 12.4857 4.31006 11.9893 4.14464C11.0048 3.81659 9.59421 3.60005 8 3.60005V2.00005C9.71829 2.00005 11.3077 2.231 12.4951 2.62671C13.0846 2.82316 13.6195 3.07474 14.0234 3.39299C14.4183 3.70414 14.8 4.17356 14.8 4.80005H13.2ZM8 3.60005C6.40579 3.60005 4.99516 3.81659 4.01073 4.14464C3.51433 4.31006 3.17029 4.48942 2.96679 4.64976C2.75427 4.81721 2.8 4.87403 2.8 4.80005H1.2C1.2 4.17356 1.58167 3.70414 1.97657 3.39299C2.38049 3.07474 2.91536 2.82316 3.5049 2.62671C4.69234 2.231 6.28171 2.00005 8 2.00005V3.60005Z"
        fill="#00FFBF"
        mask="url(#path-22-inside-9_9_7682)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_9_7682"
          x1="8"
          y1="5.19995"
          x2="8"
          y2="7.19995"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FFBF" />
          <stop offset="1" stopColor="#00FFBF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_9_7682"
          x1="8"
          y1="4.40002"
          x2="8"
          y2="6.40002"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FFBF" />
          <stop offset="1" stopColor="#00FFBF" />
        </linearGradient>
      </defs>
    </svg>
  )
}
