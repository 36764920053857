// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/fonts/Sohne-Buch.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../assets/fonts/SohneMono-Buch.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'Sohne-Buch';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('opentype');
}
@font-face {
  font-family: 'SohneMono-Buch';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('opentype');
}

body {
  overscroll-behavior-y: none;
}
`, "",{"version":3,"sources":["webpack://./src/style.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,+DAA6D;AAC/D;AACA;EACE,6BAA6B;EAC7B,+DAAiE;AACnE;;AAEA;EACE,2BAA2B;AAC7B","sourcesContent":["@font-face {\n  font-family: 'Sohne-Buch';\n  src: url('../assets/fonts/Sohne-Buch.otf') format('opentype');\n}\n@font-face {\n  font-family: 'SohneMono-Buch';\n  src: url('../assets/fonts/SohneMono-Buch.otf') format('opentype');\n}\n\nbody {\n  overscroll-behavior-y: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
